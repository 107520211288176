import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Link from "next/link";
import Image from "next/image";

import carouselBGimg2 from "../../assets/images/home/Banner/UPSC-CSE-2024-Webbanner.jpg";
import carouselBGimg3 from "../../assets/images/home/Banner/UPSC-Result-2023-Web-Banner.webp";
import carouselBGimg5 from "../../assets/images/home/Banner/UPSC-Result-2023-National Topper-Webpanner.webp";
import carouselBGimg6 from "../../assets/images/home/Moksha/Moksha-2025-Web-Banner-Clickhere.jpg";
import carouselBGimg4 from "../../assets/images/home/Banner/TNPSC-GROUP-I&II-Web-banner-Button.jpg";
import carouselBGimg7 from "../../assets/images/home/Banner/Chakra-web-banner-with-button.jpg";
import carouselBGimg10 from "../../assets/images/home/Banner/TNPSC-mock-test-web-banner-with-button.jpg";
import carouselBGimg8 from "../../assets/images/home/Banner/Sadhana-webbanner-2026.jpg";
import carouselBGimg9 from "../../assets/images/home/Banner/TNPSC-New-location-Web-banner-Clickhere.jpg";
import carouselBGimg11 from "../../assets/images/home/Banner/Congralutations-banner.jpg";
import carouselBGimg12 from "../../assets/images/home/Banner/TNPSC-GR-I-Mains-Test-Series_Web-Banner.jpg";
import carouselBGimg13 from "../../assets/images/home/Banner/Web/Forestry-Geology-Optional-Web-Banner.jpg";
import carouselBGimg14 from "../../assets/images/home/Banner/Web/TNPSC-GrII-Mains-WebBanner-Clickhere.webp";
import carouselBGimg16 from "../../assets/images/home/Banner/Web/TNPSC-GR-I-Result-2022_Webbanner.webp";
import carouselBGimg17 from "../../assets/images/home/Banner/SI-Test-Series-Webbanner.jpg";
import carouselBGimg18 from "../../assets/images/home/Banner/TNPSC-Gr1-Web-Banner-Click-Here.jpg";
import carouselBGimg19 from "../../assets/images/home/Banner/Prestorming/Prestorming-web-banner-batch-III-button.jpg";
import carouselBGimg20 from "../../assets/images/home/Banner/Web/Forest-Services-WebBanner.webp";
import carouselBGimg21 from "../../assets/images/home/Banner/Interview/Interview-Web-banner-Clickhere.jpg";


{/* mobile */}
import carouselMBimg1 from "../../assets/images/home/Mob_Banner/TNPSC-mock-test-mob-with-button.jpg";
import carouselMBimg2 from "../../assets/images/home/Mob_Banner/UPSC-CSE-2024-Mobbanner.jpg";
import carouselMBimg3 from "../../assets/images/home/Mob_Banner/UPSC-Result-2023-Moblie-Banner.webp";
import carouselMBimg4 from "../../assets/images/home/Mob_Banner/TNPSC-GROUP-I&II-Mob-banner-Button.jpg";
import carouselMBimg10 from "../../assets/images/home/Mob_Banner/Chakra-Mob-banner-button.jpg";
import carouselMBimg13 from "../../assets/images/home/Banner/Web/Forestry-Geology-Optional-Mob-Banner.jpg";
import carouselMBimg5 from "../../assets/images/home/Mob_Banner/UPSC-Result-2023-National-toppers-Mobilepaner.webp";
import carouselMBimg6 from "../../assets/images/home/Mob_Banner/Foresty-Result-2023-Moblie-Banner.jpg";
import carouselMBimg7 from "../../assets/images/home/Mob_Banner/Sadhana-Mobile-Banner-2026.jpg";
import carouselMBimg8 from "../../assets/images/home/Mob_Banner/Congralutations-banner.jpg";
import carouselMBimg9 from "../../assets/images/home/Mob_Banner/TNPSC-New-location-Mobile-banner-Clickhere.jpg";
import carouselMBimg11 from "../../assets/images/home/Moksha/Moksha-2025-Mob-Banner-Clickhere.jpg";
import carouselMBimg12 from "../../assets/images/home/Mob_Banner/TNPSC-GRI-Mains-Test-Series-Mobille-Banner.jpg";
import carouselMBimg14 from "../../assets/images/home/Mob_Banner/TNPSC-GrII-Mains-MobBanner-Clickhere.jpg";
import carouselMBimg16 from "../../assets/images/upsc/G1-Result/TNPSC-GR-I-Result-2022-Mobilebanner.jpg";
import carouselMBimg17 from "../../assets/images/home/Mob_Banner/SI-Test-Series-Mobilebanner.jpg";
import carouselMBimg18 from "../../assets/images/home/Mob_Banner/TNPSC-Gr1-Mobile-Banner-Click-Here.jpg";
import carouselMBimg19 from "../../assets/images/home/Banner/Prestorming/Prestorming-Mob-banner-Batch-III-with-button.jpg";
import carouselMBimg20 from "../../assets/images/home/Banner/Web/Forest-Services-MobileBanner.webp";
import carouselMBimg21 from "../../assets/images/home/Banner/Interview/Interview-Mob-banner-Clickhere.jpg";



function slider() {

    const BGimg = [
      { quickLoading: false, img: carouselBGimg10, alt: "carousel", link: "https://www.shankariasacademy.com/blog/tnpsc-group-i-main-mock-test/" },
        { quickLoading: false, img: carouselBGimg13, alt: "carousel", link: "/forestry-admission" },
        { quickLoading: false, img: carouselBGimg20, alt: "carousel", link: "/upsc-ifos-optional-test-series" },
        { quickLoading: false, img: carouselBGimg21, alt: "carousel", link: "/upsc-interview" },
        { quickLoading: false, img: carouselBGimg7, alt: "carousel", link: "/current-affairs-upsc" },   
        { quickLoading: false, img: carouselBGimg6, alt: "carousel", link: "/upsc-mains-test-series" },
        { quickLoading: false, img: carouselBGimg19, alt: "carousel", link: "/upsc-prestorming" },
        { quickLoading: false, img: carouselBGimg4, alt: "carousel", link: "/tnpsc-admissions/#gspcm" },
        { quickLoading: false, img: carouselBGimg14, alt: "carousel", link: "/tnpsc-group-II-mains-test-series" },
        { quickLoading: false, img: carouselBGimg18, alt: "carousel", link: "/tnpsc-group-i-mains-crash-course" },
        { quickLoading: false, img: carouselBGimg12, alt: "carousel", link: "/tnpsc-group-i-mains-test-series" },
        { quickLoading: false, img: carouselBGimg11, alt: "carousel", link: "/upsc-admission-anna-nagar" },
        { quickLoading: false, img: carouselBGimg2, alt: "carousel", link: "/upsc-admission-anna-nagar" },
        { quickLoading: false, img: carouselBGimg3, alt: "carousel", link: "/upsc-admission-anna-nagar" },
        { quickLoading: false, img: carouselBGimg5, alt: "carousel", link: "/upsc-admission-anna-nagar" },
        
        { quickLoading: false, img: carouselBGimg17, alt: "carousel", link: "/si-test-series" },
        { quickLoading: false, img: carouselBGimg8, alt: "carousel", link: "/two-year-upsc-program" },
        { quickLoading: false, img: carouselBGimg16, alt: "carousel", link: "/tnpsc-admissions" },
        { quickLoading: false, img: carouselBGimg9, alt: "carousel", link: "/tnpsc-admissions" },
       
      ]

      const MBimg = [
        { quickLoading: false, img: carouselMBimg1, alt: "carousel", link: "https://www.shankariasacademy.com/blog/tnpsc-group-i-main-mock-test/" },
        { quickLoading: false, img: carouselMBimg13, alt: "carousel", link: "/forestry-admission" },
        { quickLoading: false, img: carouselMBimg20, alt: "carousel", link: "/upsc-ifos-optional-test-series" },
        { quickLoading: false, img: carouselMBimg21, alt: "carousel", link: "/upsc-interview" },
        { quickLoading: false, img: carouselMBimg11, alt: "carousel", link: "/upsc-mains-test-series" },
        { quickLoading: false, img: carouselMBimg10, alt: "carousel", link: "/current-affairs-upsc" },
        { quickLoading: false, img: carouselMBimg19, alt: "carousel", link: "/upsc-prestorming" },
        { quickLoading: false, img: carouselMBimg7, alt: "carousel", link: "/two-year-upsc-program" },
        { quickLoading: false, img: carouselMBimg8, alt: "carousel", link: "/upsc-admission-anna-nagar" },
        { quickLoading: false, img: carouselMBimg4, alt: "carousel", link: "/tnpsc-admissions/#gspcm" },
        { quickLoading: false, img: carouselMBimg14, alt: "carousel", link: "/tnpsc-group-II-mains-test-series" },
        { quickLoading: false, img: carouselMBimg18, alt: "carousel", link: "/tnpsc-group-i-mains-crash-course" },
        { quickLoading: false, img: carouselMBimg12, alt: "carousel", link: "/tnpsc-group-i-mains-test-series" },
        { quickLoading: false, img: carouselMBimg3, alt: "carousel", link: "/upsc-admission-anna-nagar" },
        { quickLoading: false, img: carouselMBimg5, alt: "carousel", link: "/upsc-admission-anna-nagar" },
        { quickLoading: false, img: carouselMBimg6, alt: "carousel", link: "/upsc-admission-anna-nagar" },
        { quickLoading: false, img: carouselMBimg2, alt: "carousel", link: "/upsc-admission-anna-nagar" },
        { quickLoading: false, img: carouselMBimg17, alt: "carousel", link: "/si-test-series" },
        { quickLoading: false, img: carouselMBimg16, alt: "carousel", link: "/tnpsc-admissions" },
        { quickLoading: false, img: carouselMBimg9, alt: "carousel", link: "/tnpsc-admissions" },

      ]
  
  return (
    <>
        {/* Carousel large devices*/}
      <div className="container-fluid">
        <div className="row">
          <div className="col px-0 d-none d-md-block">
            <Carousel>
              {BGimg.map((e, index) =>
                <Carousel.Item key={index}>
                  <Link href={e.link} rel="noreferrer">
                    <Image
                      className="carouselimage img-fluid w-100 h-100"
                      src={e.img}
                      alt={e.alt}
                      title="Shankar IAS Academy"
                      loading={e.quickLoading ? "eager" : "lazy"}
                      placeholder="blur"
                    /></Link>
                </Carousel.Item>
              )}
            </Carousel>
          </div>
        </div>
      </div>

      {/* Carousel Mobile devices*/}
      <div className="container-fluid">
        <div className="row">
          <div className="col px-0 d-block d-md-none">
            <Carousel>
              {MBimg.map((e, index) =>
                <Carousel.Item key={index}>
                  <Link href={e.link} rel="noreferrer">
                    <Image
                      className="carouselimage img-fluid"
                      src={e.img}
                      alt={e.alt}
                      title="Shankar IAS Academy"
                      loading={e.quickLoading ? "eager" : "lazy"}
                      placeholder="blur"
                    /></Link>
                </Carousel.Item>
              )}
            </Carousel>
          </div>
        </div>
      </div>

    </>
  )
}

export default slider